@font-face {
    font-family: 'open_sanslight';
    src: url('../fonts/opensans-light_0-webfont.woff2') format('woff2'),
         url('../fonts/opensans-light_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans-regular_0-webfont.woff2') format('woff2'),
         url('../fonts/opensans-regular_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'open_sanssemibold';
    src: url('../fonts/opensans-semibold_0-webfont.woff2') format('woff2'),
         url('../fonts/opensans-semibold_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'open_sansbold';
    src: url('../fonts/opensans-bold_0-webfont.woff2') format('woff2'),
         url('../fonts/opensans-bold_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


html{font-family: 'open_sansregular'; font-size:14px;}
body{ font-family: 'open_sansregular'; font-size: 1.28rem; color: #353535; line-height:normal; position: relative;}

::-webkit-scrollbar{ width: 8px; height: 8px; -webkit-box-shadow: inset 0px 0px 6px rgba(0,0,0,0);  -webkit-border-radius: 8px; border-radius: 8px;}
/*add a shadow to the scrollbar here*/
::-webkit-scrollbar-track{ -webkit-box-shadow: inset 0px 0px 0px rgba(0,0,0,0);  -webkit-border-radius: 8px; border-radius: 8px;}
/*this is the little scrolly dealio in the bar*/ 
::-webkit-scrollbar-thumb{ border-radius: 8px; background-color: rgba(0,0,0,0.2); height: 3px;}
/*nobody needs this little scrollbar corner, I mean really, get rid of it haha*/  
::-webkit-scrollbar-corner{ display: none; height: 0px; width: 0px;}

img { max-width: 100%; display: inline-block;}
button, input, textarea, select { font-family: 'open_sansregular';opacity: 1; letter-spacing: 0.0143rem;}
button:focus, input:focus, textarea:focus, select:focus{ outline: none;}
ul, li { padding: 0px; margin: 0px; list-style: none;}
a { display: inline-block; -webkit-transition: all 0.5s ease-out; transition: all 0.5s ease-out; color: #dd2a2a;}
a:hover, a:focus { outline: inherit; text-decoration: none;}
a:hover { color: #2e56bf;}
h1, h2, h3, h4, h5, h6 {font-family: 'open_sansregular'; line-height: normal; padding: 0px 0px; margin: 0px 0px;}

section::after{ display: block; clear: both; content: '';}

.full_container{ max-width: 1920px; width: 100%; overflow: hidden; position: relative; margin: 0rem auto;}

.form-control::-webkit-input-placeholder{ color:#999999; opacity:1;}
.form-control::-moz-placeholder{ color:#999999; opacity:1;}
.form-control::-ms-input-placeholder{ color:#999999; opacity:1;}
.form-control:-ms-input-placeholder{ color:#999999; opacity:1;}
.form-control::placeholder{ color:#999999; opacity:1;}



.top-bar{ background-color:#f4f3f7;}
.location .location-tag{ font-size:1rem; color:#666666;}
.location .location-tag i{ font-size:1.5rem; color:#999999;}
.currency .country-flag{ width:1.61rem;}
.language .custom-select{ width:5.5rem;}

.custom-select{ font-size:1rem; color:#666666; width:6.5rem; background-image: url(../img/custom_select.svg); background-size:.75rem;}
.custom-select:focus{ box-shadow:none;}



.logo{ display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -ms-flex-align: center;}
.logo img { width:15.78rem; display: inline-block; -webkit-transition: all 0.5s ease-out; transition: all 0.5s ease-out;}

.navbar-expand-md{ -ms-flex-flow: wrap; flex-flow: wrap; padding:1rem 0rem .42rem;}
.navbar.fixed-top.navbar-expand-md{ padding: 0.5rem 15px;}
.navbar-expand-md > .container{ -webkit-box-align: stretch; -ms-flex-align: stretch; align-items: stretch; -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; -ms-flex-wrap: wrap; flex-wrap: wrap;}

/*.navigation.fixed-top .logo img { max-width: 12rem;}*/
.navigation{ width: 100%; position: absolute; top:0%; left: 0%; z-index: 9; border-bottom:2px solid #f2f2f2;}
.navbar.fixed-top{ width: 100%; position: fixed; top: 0%; -webkit-animation:slide-down 0.7s; animation:slide-down 0.7s; -webkit-box-shadow: 0px 0.071rem 0.71rem 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 0.071rem 0.71rem 0px rgba(0, 0, 0, 0.2); /*background-color: #080d2d;*/ z-index: 9999; background:#fff;}
    
.navigation.inner_header{ background-color: #080d2d; -webkit-box-shadow: 0px 0.071rem 0.71rem 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 0.071rem 0.71rem 0px rgba(0, 0, 0, 0.2);}
.navigation.inner_header .navbar-expand-md{ padding: 1.5rem 0rem;}


@keyframes slide-down{
    0%{ opacity: 1; -webkit-transform: translateY(-100%); transform: translateY(-100%);}
    100%{ opacity: 1; -webkit-transform: translateY(0); transform: translateY(0);} 
}

.navigation .navbar-toggler { width: 40px; height: 35px; line-height: 0; color: #ffffff; border-color:#ff0000; background-color: transparent; padding: 0px 0px; margin: 0rem 0rem 0rem auto;}
.navigation .navbar-toggler-icon { width: 25px; height: 3px; display: block; background-color:#ff0000; margin: 4px auto;}
.navigation .navbar-toggler:focus { outline: none; outline-offset: 0px;}
.navigation .navbar-toggler:hover { background-color: transparent;}
.navigation .navbar-toggler:hover .navbar-toggler-icon { background-color:#ff0000;}

.navigation .navbar-nav li{display: -webkit-inline-box; align-items:center; display: -ms-inline-flexbox; display: inline-flex; position: relative; padding: 0rem 1rem;}
.navigation .navbar-nav li span{ color:#666666;}
.navigation .navbar-nav li:last-child{ padding-right:0}
.navigation .navbar-nav li a{ position:relative; font-size:1rem; color:#666666;  display: -moz-flex; display: -ms-flex; display: -o-flex; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
.navigation .navbar-nav li:hover a,
.navigation .navbar-nav li a.active{ color:#ff0000;}
.Service-text{ position:absolute; bottom:0; font-size:1rem; color:#666666; left:8rem;}


.btn-order{width:9.28rem; height:2.85rem;  font-family: 'open_sanssemibold';}
.btn-blue{ background-color:#16498a!important;}
.btn-blue:hover{background-color:#ff0000!important;}
.btn-blue img{ width:1.92rem;}

.btn{ background-color:#ff0000; color:#fff!important;font-size:1rem; -webkit-transition:all 0.5s ease-out; transition:all 0.5s ease-out; }
.btn:hover{background-color:#16498a;}

.home_banner_block{position:relative;}
.home_banner_block .item:before{ background-color:rgba(22,73,138,0.70); position:absolute; left:0; right:0; width:100%; top:0; bottom:0; content:''; z-index:9;}
.home_banner_block .item{height:32.14rem; width:100%; background-size:cover; background-repeat: no-repeat; background-position: center center;}

.banner_data{ position:relative; z-index:1; max-width:650px; z-index:8; margin:0 auto; position:absolute; left:50%; top:50%; transform:translate(-50%,-50%);}
.banner_title{ font-size:3.5rem; color:#fff;   font-family: 'open_sansbold'; line-height:1;}
.banner-search{background-color:rgba(74, 43, 37, 0.20); padding:0.42rem; border-radius:0.25rem}
.banner-search .form-control{ height:3.57rem; width:calc(100% - 9.5rem);box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.28); font-size:1rem; color:#999999; padding-left:3.5rem;}
.banner-search .btn-search{ width:8.92rem; height:3.57rem; font-size:1.21rem; display:flex; align-items:center; justify-content:center;}
.banner-search .search-icon{ position:absolute; left:2rem; top:50%; color:#b4b4b4; transform:translateY(-50%); display:block;}

.home_banner_block .owl-prev,
.home_banner_block .owl-next{ margin-top:-1.78rem; width:3.5rem; height:3.57rem; border-radius:100%; background:rgba(0, 0, 0, 0.50)!important; -webkit-transition: all 0.5s ease-out; transition: all 0.5s ease-out;}
.home_banner_block .owl-prev span,
.home_banner_block .owl-next span{ color:#fff; font-size:3rem; line-height:1.78rem;}
.home_banner_block .owl-prev:hover,
.home_banner_block .owl-next:hover{ background-color:#ff0000!important;} 

.owl-nav{ position:absolute; left:0rem; top:50%; transform:translateY(-50%); z-index:9; right:0;}
.owl-prev span,
.owl-next span{ color:#16498a;  font-size:5rem; line-height:0; margin-top: -11px; display:block;}
.owl-next{right:1rem; position:absolute;}
.owl-prev{left:1rem; position:absolute;}

.sec-title{ color:#3b3b3b; font-size:2.28rem;  font-family: 'open_sansbold';}
.red-text{ color:#ff0000;}
.view-all{ color:#fff; font-size:1.07rem; background-color:#16498a; width:5.71rem; height:2.28rem; border-radius:0.25rem; }
.view-all:hover{background-color:#ff0000; color:#fff;}

.latest-sec{ background-color:#f4f3f7; padding:4.28rem 0rem 3.57rem;}
.latest-sec .owl-next{right:-8rem; }
.latest-sec .owl-prev{left:-8rem;}


.categories-sec{ position:relative; height:35.71rem; padding:4rem 0rem;}
.categories-sec .slider-sec{position: absolute; left: 0; top: 50%; transform: translateY(-50%); width:90%; padding-top:6rem;}
.categories-sec .item{ padding:2rem 1rem;}
.categories-sec .owl-next{ right:8rem;}
.categories-sec .owl-prev{ left:8rem;}
.categories-sec .owl-carousel.owl-loaded{ padding-right:10rem;}
.categories-sec .center .box-white{ border-color:#16498a;}
.categories-sec .center .box-white .title{color:#16498a;}

.box-white{box-shadow: 0px 7px 21px 0px rgba(178, 178, 178, 0.28); height:21.42rem; padding:0.5rem; border:1px solid transparent;}
.box-white img{ width:auto!important; margin:0 auto;}
.box-white .title{ color:#333333; font-size:1.5rem; font-family: 'open_sanssemibold';}

.order-sec .owl-next{right:-2rem; }
.order-sec .owl-prev{left:-2rem;}
.order-box{ display:flex; align-items:center; justify-content:center; flex-wrap:wrap;}
.order-box img{ width:100%; height:100%;}
.rounded-box{width:15.57rem; height:15.57rem; border-radius:100%; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18); overflow:hidden; border:6px solid #ffffff;}
.rounded-box img{ object-fit:cover;}
.order-box .title{ color:#474747; font-size:1.42rem; font-family: 'open_sanssemibold';}

.order-sec  .owl-prev span,
.order-sec  .owl-next span{ color:#afafaf;}

.features-sec{ background-color:#f4f3f7; padding:4rem 0rem 2rem;}
.box-features {box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10); border-radius:1.42rem;  margin-bottom:2.5rem;}
 .color-yellow{ color:#fac917;}    
.box-features .user-name{ color:#666666; font-size:1.07rem;}
.box-features span{ color:#16498a; font-family: 'open_sanssemibold'; font-size:1.28rem;}
.box-features p{ color:#999999; font-size:.93rem;}
.features-img{border-radius:1.42rem; overflow:hidden; height:12.5rem;}
.features-img img{ height:100%; width:100%; object-fit:cover;}


.how-work-features{background-image: url(../img/how-work.jpg); background-repeat: no-repeat; background-size:100% auto; background-position:center center; padding:3rem 0rem 6.40rem;}
.how-work-features .sec-title{ color:#f4f3f7;}
.how-work-features .row{ position:relative;}
.how-work-features .row:before{ position: absolute; left: 14rem; top:25px; content: ''; width:70%; height: 99px; background: url(../img/dotted-line.png) no-repeat 0 0 / contain; z-index: 1;}
.how-work-content .title{ color:#fff; font-size:1.42rem; font-family: 'open_sanssemibold';}
.how-work-content p{ font-size:1rem; color:#fff; font-family: 'open_sanslight';}



footer{width: 100%; position: absolute; bottom: 0%; background:#fff; border-top:2px solid #16498a;}
footer .space{ padding:4.28rem 0rem; position:relative; z-index:1;} 
footer:before{ position:absolute; left:0; top:0; content:''; background-image: url(../img/pattern-l.png); background-repeat: no-repeat; background-size:cover; background-position:top left; width:19.71rem; height:23.14rem;}
footer:after{ position:absolute; right:0; top:0; content:''; background-image: url(../img/pattern-r.png); background-repeat: no-repeat; background-size:cover; background-position:top left; width:25.28rem; height:23rem;}

.footer-title{ color:#fff; font-size:1.28rem; color:#828282; font-family: 'open_sanssemibold';}
.footer-menu{color:#989898;}
.footer-menu li a{ color:#999999; font-size:1rem; }
.footer-menu li a:hover{ color:#ff0000;}
.social-link  a{width:3rem;height:3rem;border-radius:100%; background-color:#464646;display:flex; color:#ffff; font-size:1.8rem; align-items:center; justify-content:center;}
.social-link a:hover { background:#ff0000;}
.footer-logo{ width:12rem;}
.footer-col{ max-width:150px; margin:0 auto;}


.copyright{ color:#fff; font-size:1rem; background-color:#171518; padding:1.42rem 0rem; position:relative; z-index:2;}




/*list page***/

.search-restaurant{ background-color:#f6f6f6;}
.search-restaurant .banner_data{ position:inherit; transform:inherit; top:inherit; left:inherit;}
.breadcrumb .breadcrumb-item{ color:#999999; font-size:0.92rem; display:flex; align-items:center;}
.breadcrumb .breadcrumb-item i{ font-size:1.4rem;}
.breadcrumb .breadcrumb-item:hover{color:#16498a;}
.breadcrumb .breadcrumb-item a{ color:#16498a;}
.breadcrumb-item+.breadcrumb-item::before{ content:"\f054"; padding-left:0.2rem; font:normal normal normal 14px/1 FontAwesome; font-size:0.85rem;}



.mobile-filter{ width:100px; height:40px;background:red; color: #fff; display: flex; align-items: center; justify-content: center; border-radius:4px; font-size:1.28rem; font-family: 'open_sansbold';
}

.sidebar{ background-color:#f9f9f9; box-shadow:2px 0px 0px 0px rgba(78, 78, 78, 0.16); padding-bottom:10rem;}
.sidebar .filter-box{ border-bottom:1px solid #e8e2dc}
.sidebar .filter-title{ color:#666666; font-size:1rem;  font-family: 'open_sansbold';}
.sidebar .filter-title span{ font-size:0.92rem; color:#333333;  font-family: 'open_sansregular';}
.sidebar .custom-select{ height:2.71rem;}
.sidebar .rating-list li{ color:#cccccc; padding-bottom:0.8rem;}


.sidebar label > input[type="radio"] { display: none;}
label > input[type="radio"] + *::before {content: ""; display:block;   width: 1rem; height: 1rem; margin-right: 0.3rem; border-radius: 50%; border-style: solid; border-width: 0.1rem; border-color: gray;}
.sidebar label > input[type="radio"]:checked + *::before {background: radial-gradient(#16498a, #16498a, transparent 50%, transparent); }
.sidebar .irs--flat .irs-min, .irs--flat .irs-max{ display:none;}




.irs--flat .irs-bar{ background:red; border-radius:10px;}
.irs--flat .irs-handle>i:first-child{ background:red; width:24px; height:24px; margin-left:-6px; margin-top:-4px; border-radius:100%;}
.irs--flat .irs-line{ border-radius:10px; background-color:#f1f1f1; border:1px solid #c7c7c7; }
.irs-from, .irs-to, .irs-single {top:50px; background-color:transparent!important; color:#16498a!important; font-size:0.92rem!important;  font-family: 'open_sanssemibold';}
.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before{ display:none;}


.js-range-km .irs-single{ display:none;}
.js-range-km .irs-grid-pol.small{ display:none;}
.js-range-km .irs-grid-pol{ height:20px; top:-18px;}
.js-range-km .irs--flat .irs-handle>i:first-child{ background-color:#16498a;}

.paginations{ position:inherit; top:inherit;}
.page-link{ height:2.64rem; width:2.64rem; border-color:#cccccc; border-radius:0; margin:0 0.2rem; color:#666666; font-size:1rem;}
.page-link:hover{ background-color:transparent; color:#633986;}
.page-item:first-child .page-link,
.page-item:last-child .page-link{border-radius:0; display:flex; align-items:center; justify-content:center; }


.features-info .order-time{ border-top:1px solid #dfdfdf;  margin:0rem -.30rem;}
.features-info .order-time div{ width:49%; border-right:1px dashed #dfdfdf;}
.features-info .order-time div img{ width:1.10rem;}
.features-info .order-time div:last-child{ border:none;}
.features-info .order-time p{ color:#666666; font-size:0.85rem; line-height:1.2rem; width:calc(100% - 1.10rem);}
.features-info .order-time p strong{ font-size:1rem; color:#16498a;  font-family: 'open_sansbold';}
.features-info .open-tag{ color:#13c24d; font-size:.92rem; width:16%;  }
.btn-order{ font-size:1.14rem; font-family: 'open_sansbold';}


.btn-cart{ font-size:1.5rem!important; font-family: 'open_sansbold';}
.btn-cart{width:3.88rem; height:2.85rem;  font-family: 'open_sanssemibold';}


/**product detail page***/

.product-sidebar{ width:calc(100% - 290px); padding-right:50px;}
.brand-img{width:8.92rem; height:8.92rem; background-color:#fff; border:1px solid #e8e8e8; border-radius:4px; display:flex; align-items:center; justify-content:center; margin-top:-4rem;}
.brand-info{ width:calc(100% - 8.92rem);}
.brand-info .rating{ color:#666666; font-size:1.07rem;}
.brand-info .rating i{ font-size:1.3rem;}
.brand-info .title{ color:#16498a; font-family: 'open_sansbold'; font-size:2.57rem;}
.brand-info .text-red{ color:#ff0000; font-size:1.14rem;}
.brand-info p{ color:#898989; font-size:1rem;}


.product-info .nav-tabs .nav-link{ border-radius:0px!important; border:0; border-bottom:3px solid transparent; color:#333333; font-size:1.28rem;  padding:.9rem 3rem;}
.product-info .react-tabs__tab--selected .nav-link{ border-color:#16498a!important;}


.product-info .name{ color:#16498a; font-size:1.42rem; font-family: 'open_sansbold';}
.product-info p{ color:#898989; font-size:1.14rem;}
.product-info .text-blue{color:#16498a; }
.product-info .open-tag { color:#00a14c; font-size:1rem;}
.product-info .more-link{ font-size:1rem; color:#16498a; }


.product-main{ width:290px;}
.product-main .title{ background-color:#e7ecf3; color:#000000; margin:.1rem; font-size:1.28rem; font-family: 'open_sansbold'; padding:0.80rem 1rem;}
.product-main .title i{ color:#16498a; font-size:2rem;}
.product-main .form-control{ color:#999999; font-size:1rem; height:3rem;}
.product-main .btn-location{ color:#ffffff; font-size:1.14rem; font-family: 'open_sansbold'; height:3.35rem; background-color:#16498a; display:flex; align-items:center; justify-content:center;}
.product-main .btn-find{position:absolute; right:1rem; top: 50%; transform:translateY(-50%); background:#fff; border:0; height:90%; width:25px; appearance:none; color:#e0e0e0; font-size: 2rem;}

.product-main li figure{width:3rem;}
.product-main li figure img{ width:100%; height:100%; object-fit:cover;}
.product-main li .content{ width:calc(100% - 3rem)}
.product-main li .name{ color:#333333;font-family: 'open_sansbold'; font-size:1.14rem; line-height:1;}
.product-main li p{font-size:0.92rem; color:#666666;}
.product-main li i{ font-size:1.2rem;}
.product-main .rating-count{font-size:0.92rem; color:#666666; display: flex; align-items: center;}




/**checkout**/

 .brand-info-checkout{box-shadow: 0px 0px 4px 0px rgba(198, 198, 198, 0.64); }
 .lead-text{ font-size:1rem!important;} 
 .delivery-address{ background-color:#f5f7fa; border:1px solid #dfdfdf;}
 .delivery-address span{ font-size:1rem; color:#16498a;}
 .delivery-address p{ font-size:1rem; color:#898989;}
 .delivery-address .link-change{ color:#16498a; font-size:1rem;}
 .delivery-address .link-change:hover{ color:red;}
 .price-list{color:#333333; font-size:1rem;}
 .price-list .total{ font-size:1.28rem; color:#000000; font-family: 'open_sansbold';}


.checkout-main .title{ font-size:1.14rem; color:#16498a;}
.checkout-main .heading{ color:#333333; font-size:1.57rem;}

.order-info .name{ font-size:1.28rem; color:#000; position:relative; padding-left:2rem;}
.order-info .name:before{ position:absolute; left:0; top:0.5rem; content:''; width:1.28rem; height:1.28rem;  background-image: url(../img/point.png);  background-repeat: no-repeat; background-size:cover; }
.order-info p{ color:#7d7d7d; font-size:1.07rem;}

.add-btn{border-radius:2px; border:1px solid #16498a; }
.add-btn button, 
.add-btn input{ width:28px; text-align:center; height:28px; line-height:0.5; background-color:#fff; border:0; font-size:1.5rem; color:#16498a;}
.add-btn input[type="text"]{ background-color:#16498a; color:#fff; font-size:1rem;}


.radio_list{ display:flex; align-items:center; flex-wrap:wrap; margin:0 -5px;}
.radio_list li{ position:relative; font-size:1rem; color:#2d2d2d; margin:0 5px;}
.radio_list input[type="radio"]{width:100%; height:100%; position:absolute; opacity:0; }
.radio_list span{ width:18px; height:18px; margin-right:5px; border-radius:100%; background-color:#fff; border:2px solid #eeeeee; display:inline-block; vertical-align:middle;}
.radio_list input[type="radio"]:checked + span{background: radial-gradient(#1a4c8c, #1a4c8c, transparent 40%, transparent); border-color:#1a4c8c;}

.checkout-main .form-control{ width:100px;}
.checkout-main .custom-select{ width:5rem;}
.checkout-main .promo-text{ font-size:1rem; color:#aaaaaa;}
.checkout-main .link-code{ font-size:1.07rem; color:#16498a;}
.checkout-main .bg-grey{ background-color:#f8f8f8; }
.checkout-main .bg-grey.title{color:#000;}
.checkout-main .color-grey{ color:#aaaaaa!important;}

/** logion **/
.login-page{ position:relative;}
.login-page:after{ width:45%; height:100%; content:''; position:absolute; right:0; top:0; background-image: url(../img/login-right.jpg);  background-repeat: no-repeat; background-size:cover; background-position:left center;  border-left:1px solid #fd8c2d; border-right:3px solid #fd8c2d;}
.login-page:before{ width:55%; height:100%; content:''; position:absolute; left:0; top:0;background-image: url(../img/login-left.jpg);  background-repeat: no-repeat;  background-size:cover; background-position:0 0; border-right:30px solid red;}

.login-box{ max-width:470px;}
.logo-login{ width:8.28rem; height:8.28rem; margin:0 auto; box-shadow: 0px 0px 7px 0px rgba(194, 194, 194, 0.90); border-radius:1.5rem; overflow:hidden;}
.login-title{ color:#3e3e3e; font-size:1.5rem;}
.login-box .text-blue{ color:#16498a; font-size:2.14rem;}
.login-box .form-control{ border-color:#d6d6d6; font-size:1rem; color:#999999; height:3rem; padding-right:2.2rem;}


.login-box .form-control:focus{ box-shadow:none;}
.login-box .icon-field{ position:absolute; right:1rem; top:50%; transform:translateY(-50%); color:#ce0000; font-size:1.4rem;}
.login-box .forgot-password{ font-size:1rem; color:#ce0000;}
.login-box .form-check-label{ font-size:0.92rem; color:#666666; padding-left:0.5rem;}
.login-box .form-check-input {height:1.21rem;width: 1.21rem;appearance: none; display:flex; align-items:center; justify-content:center; background-color:#fff; border:1px solid #d6d6d6; border-radius:2px;}
.login-box .form-check-input:before{content: '';display: inline-block;height: 100%;width: 100%;border-radius: 0.25rem;}
.login-box .form-check-input:checked:before { content: "\f00c"; font:normal normal normal 14px/1 FontAwesome; color:#16498a; }


.login-box .btn-submit{ color:#fff; font-size:1.28rem; background-color:#16498a; height:3.42rem; width:100%;}
.login-box .btn-submit:hover{ background-color:#fff; color:#16498a!important; border-color:#16498a;}
.login-box p{ font-size:1.14rem;  color:#666666;}
.login-box p .text-link{text-decoration:underline;}
.login-box .text-link{ color:#ce0000; }


/* privacy policy */

.inner_title{ font-size: 2.3rem; color:#16498a; font-weight:800;  }
.content_block p{ font-size:1.14rem; font-weight:400; line-height:1.8;  color:#828282;}
.content_block ul{ list-style:circle;}
.content_block ul li{ font-size:1.14rem; font-weight:400;  color:#828282; margin-bottom:0.8rem; position:relative;}
.content_block ul li:before{ display:inline-block; content:"\f054"; font-family: "Font Awesome 5 free"; font-weight: 900; margin-right:1rem;}
.total-ammount{font-size: 1.28rem; color: #000000; font-family: 'open_sansbold';}
.add-btn{ width:100px;} 


/** dd**/

.order-status{ background-color:#f4f3f7;}
.status-img{ width:5rem; height:5rem; border-radius:100%; overflow:hidden;}
.status-img img{ width:100%; height:100%; object-fit:cover;}
.status-info .name{ font-size:1.28rem; font-family: 'open_sansbold';}
.status-info .id{ font-size:1rem;font-family: 'open_sansbold';}
.status-info .date{ font-size:1rem;}

.status-tabs .nav-link{ padding:0.5rem; font-size:1rem; text-transform:uppercase; color:#666666;}
.status-tabs .nav-item.show .nav-link, 
.status-tabs .nav-link.active{ color:red;}

.status-list{ border-left: 4px solid #f4f3f7;}
.status-list li{ border-bottom:1px solid #dee2e6; position:relative;}
.status-list li:last-child{ border:0;}
.status-list li:before{ position:absolute; left:-1.7rem; top:0; content:''; height:1rem; width: 1rem; border-radius:100%; background:#dee2e6;}
.status-list li.active:before{ background-color:red;}
.status-list li span{ font-size:1.16rem; font-family: 'open_sansbold';}
.status-list li p{ font-size:1rem;}

.summary-img{ width:4rem; height:4rem; border-radius:100%; overflow:hidden;}
.summary-img img{ width:100%; height:100%; object-fit:cover;}
.summary-info span{ font-size:1.16rem; font-family: 'open_sansbold'; color:#000;}
.summary-info p strong{ color:#000;}
.summary-info p{ font-size:1rem; color:#9d9d9d;}

.summary-payment{ color:#9d9d9d; font-size:1rem; } 
.summary-payment span{ color:#000; padding-right:0.5rem; font-family: 'open_sansbold'; }
.summary-payment .total{ background-color:red; color:#000; font-family: 'open_sansbold'; }


/**profil**/

.profile-menu li{ border-bottom:1px solid #dee2e6;}
.profile-menu li:last-child{ border-bottom:0;}
.profile-menu li a{ font-size:1rem; padding:0.7rem; color:#666666; display:block;}
.profile-menu li a:hover{  color:#fff; background-color:#16498a;}



.profile-user-main{height:150px;}
.profile-user-main img{object-fit: cover;width: 100%;height: 100%;}
.profile-user{ margin-top:-5rem; margin-left:1rem;}
.profile-img{border:3px solid #dee2e6; border-radius:100%;  height:9rem; width:9rem;position:relative;}
.profile-img img{ width:100%; height:100%; object-fit:cover; border-radius:100%;}
.profile-name{font-size:1.50rem; font-family: 'open_sansbold';color:#fff; text-shadow: 1px 2px 2px rgba(0, 0, 0, 1);}

.profile-form{ padding-left:10rem; padding-top:3rem;}
.profile-form .form-control{border-radius:0;  border-bottom: 1px solid #ccc; font-size:0.92rem; height:3rem;}
.profile-form .order-msg span{ font-size:1.16rem; font-family: 'open_sansbold';}
.profile-form .order-msg p{ font-size:0.92rem; color:#000;}
.profile-form .btn-update{font-family: 'open_sanssemibold'; font-size:0.85rem; background-color:#16498a;}


.pik_change {height:2rem; width:2rem; background: #c81101; border-radius: 50%;position: absolute; right: 0; bottom: 0.5rem; cursor: pointer; overflow: hidden; display: flex; align-items: center;justify-content: center;}
.pik_change input[type="file" i] {height:100%; width: 100%; cursor: pointer;text-indent: -9999px; position: absolute; left: 0; top: 0;}
.pik_change:after { content: '+'; color: #fff; font-size:1.5rem;}



/** order placed**/

.order-placed .alert-success i{ font-size:3.5rem;}
.order-placed .alert-success span{ font-size:1.28rem; font-family: 'open_sansbold'; }
.order-placed .alert-success p{ font-size:1rem;}

.order-placed  .title{ font-size:1.28rem; color:#000; font-family: 'open_sansbold'; }
.order-placed .order-summary .name{ font-size:1rem; color:red;}
.order-placed .order-summary .brand{ font-size:1.16rem; color:#000; font-family: 'open_sansbold';}
.order-placed .order-summary p{ font-size: 1rem;}
.order-placed .order-summary .time{ font-size:0.92rem; color:#666;}
.order-placed .order-summary .time i{ color:red;}
.order-placed .order-summary .btn{ border-radius:2rem;}

.order-placed .collection-box p{ font-size:0.85rem;}
.order-placed .collection-box p i{ font-size:1.16rem;}
.order-placed .collection-box .open-tag{ color:green;}
.order-placed .collection-box .brand-name{ font-size:1.16rem;}
.order-placed .collection-box .rating{ color:#fac917; font-size:1rem;}
.order-placed .collection-box img{ width:auto!important;}
.order-placed .collection-box:hover{ background-color:rgba(0,0,0,0.2);}




/** dd**/


.product-info .btn{ color:#212529!important; font-size:1.16rem; font-family: 'open_sansbold'; position:relative;}
.product-info .btn:focus, .btn:hover{ box-shadow:none; text-decoration:none;}
.product-info .btn:before{font:normal normal normal 14px/1 FontAwesome;   position:absolute; right:1rem; top:50%; transform:translateY(-50%); font-size:1.16rem;}
.product-info .btn:before{ content:"\f068";}
.product-info .btn.collapsed:before{content:"\f067";}


.product-info .menu-item{ width:7rem;}
.product-info .menu-item-info{ width:calc(100% - 7rem);}
.product-info .menu-item-info .title{ font-size:1rem;  font-family: 'open_sanssemibold';}
.product-info .menu-item-info .menu-price{ font-size:1.28rem;font-family: 'open_sansbold';}
.product-info .menu-item-info .color-blue{ color:#16498a!important;}
.product-info .menu-item-info p{ font-size:1rem;}
/*.menu-item-btn{ position:absolute; right:1rem; top:1rem;}*/
.menu-item-btn .btn-add{ color:#ff0000; height:34px; border:1px solid #ff0000; min-width:60px; text-align:center;   border-radius:0.25rem; font-size:1rem;}


.product-info .no-gutters .col-6:nth-child(odd){ border-right:1px solid #dee2e6!important; }



.resturant-menu .owl-prev span, .owl-next span{ font-size:3rem; color:red; margin-top: -5px;}
.resturant-menu .owl-prev{ left:0;}
.resturant-menu .owl-next{ right:0;}
.resturant-menu .title{ color:#6c6c6c; font-size:1rem; display:block;} 



/**product detail**/

.product-sidebar .product-img{ height:238px; overflow: hidden;}
.product-sidebar .product-img img{ width:100%; height:100%; object-fit:cover;}
  
.location a{ font-size:1rem; color:#666666;}
.input-range{ margin-top:1.5rem; margin-bottom:1rem;}
.login-page .body_section{ height:100vh;}

/** location poup**/


.location-popup .modal-content{ padding:1rem; background:red;}
.location-popup .modal-body{ background-color:#fff; padding:2rem;}
.location-popup .modal-body a{ padding-bottom:1rem;  }
.location-popup .modal-body img{ width:12rem;}
.location-popup .modal-body h3{ padding-top:0.2rem; font-family: 'open_sanssemibold';font-size:2rem; color:#000;}

.location-popup .location-form{ position:relative; margin:1rem 0rem}
.location-popup .form-control{height:3.54rem; padding:.375rem 9rem .375rem .75rem;}
.location-popup .form-control:focus{ box-shadow:none; border-color:#ced4da;}
.location-popup .centerBtn{ position:absolute; right:0; top:0; height:100%; }
.location-popup .centerBtn .btn{height:100%; width:120px; font-family: 'open_sanssemibold'; font-size:1.16rem;}
.location-popup .modal-body{ min-height:auto!important;}
.location-popup .modal-dialog{ max-width:960px; margin-left:auto; margin-right:auto;}



.location-popup .autocomplete-dropdown-container{position:absolute; top:100%;  left:0; right: 0; z-index: 9; -moz-border-radius: 5px; -webkit-border-radius: 5px; -khtml-border-radius: 5px; border-radius: 5px; box-shadow: 0 7px 7px 0.72px rgb(0 0 0 / 10%); border: 1px solid #f5f5f5;
}

.location-popup .autocomplete-dropdown-container div{font-size:1rem; color:#4A4A4A; border-bottom:1px solid #f5f5f5; padding:12px 10px 12px 20px;}
.location-popup .autocomplete-dropdown-container div:hover{background:#F5F5F5;}
.location-popup .text-danger{font-size:1.16rem;}

/**location map**/

.location-map-popup .modal-header{padding:0.8rem;justify-content:center;}
.location-map-popup .close{ position: absolute; right:5px; top:5px; background:#f11; padding: 0; width:2rem; height:2rem; border-radius: 50%; color:#fff; opacity:1;}
.location-map-popup .modal-title{font-size:1.28rem; font-family: 'open_sanssemibold'; }
.location-map-popup .addressOuterMap{ padding:0rem;}
.location-map-popup .addressOuterMap iframe{width:100%;height:500px;}
.location-map-popup .location-search-input { width:100%; ;padding:0.5rem; border:0; font-size:1.16rem;}



/** **/

.myaddressmodal .modal-header{padding:0.4rem;justify-content:center; background:red;}
.myaddressmodal .modal-title{font-size:1.28rem; font-family: 'open_sansbold'; color:#fff; }
.myaddressmodal .close{ position: absolute; right:5px; top:5px; background:#f11; padding: 0; width:2rem; height:2rem; border-radius: 50%; color:#fff; opacity:1; border:2px solid #fff;}

.myaddressmodal h5{font-size:1.28rem; font-family: 'open_sansbold'; color:red; padding-bottom:0.5rem;}
.myaddressmodal h6{font-size:1.24rem; font-family: 'open_sansbold'; color:#000; padding-bottom:0.7rem;}

.myaddressmodal label{ color:#666; font-size:1.16rem; border-bottom:1px solid #dee2e6!important; display:block; padding-bottom:0.5rem;}
.myaddressmodal li{ border:1px solid #dee2e6!important; padding:0.5rem 1rem; margin-bottom:0.5rem; border-radius:0.25rem;}
.myaddressmodal li p{ color:#000; font-size:1rem; margin:0;}
.myaddressmodal li i{ font-size:1.18rem; }
.myaddressmodal .edittext{margin:1rem 0rem 0.5rem; display:flex;}
.myaddressmodal .edittext a{ background-color:#ff0000; color:#fff!important;font-size:1rem; -webkit-transition:all 0.5s ease-out; transition:all 0.5s ease-out; border-radius: 0.25rem; margin: 0rem 0.1rem; padding:0.3rem 0.5rem; text-align: center;}

.myaddressmodal .newAddressBTN{text-align:center; padding:0.5rem 0rem;}
.myaddressmodal .newAddressBTN button{
background-color:#ff0000; color:#fff!important;font-size:1rem; -webkit-transition:all 0.5s ease-out; transition:all 0.5s ease-out; border-radius: 0.25rem;
padding:0.7rem 1rem; text-align: center; border:none; font-size:1.16rem;}
.myaddressmodal .form-control{ height:3rem; font-size:1.16rem;}

/**switch btn**/
.switch {
  position: relative;
  display: inline-block;
  width:55px;
  height:31px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left:0px;
  bottom:3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
box-shadow:3px 0px 9px 0px rgba(0, 0, 0, 0.25);
}

input:checked + .slider {
  background-color: #ff0000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ff0000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.switch {
  position: relative;
  display: inline-block;
  width:55px;
  height:31px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left:0px;
  bottom:3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
box-shadow:3px 0px 9px 0px rgba(0, 0, 0, 0.25);
}

input:checked + .slider {
  background-color: #ff0000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ff0000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.show{ display: block; }


.color-yellow .fa{ font-size: 1.1rem; }
.product-main .border{  position:sticky; top:135PX;} 
.full_container{  overflow:inherit;}

form.login-form .text-danger {
  font-size: 1rem;
}



.overlay_loader{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; 
  background: rgba(0,0,0,.7);
  z-index: 9999;
}

.overlay_loader .overlay_div{
  position: absolute;
  width: 300px;
  height: 300px;
  left: 50%;
  margin-left: -150px;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  top: 50%;
  z-index: 9999;
  border-radius: 5px;
  text-align: center;
}

.overlay_loader .loader_img{
  width: 250px;
}

.overlay_div span{
  font-size: 20px;
  display: block;
  color: #273a4a;
}


/**=================profile-pages-style=============**/

.prfile-pages .profile-menu li {
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  padding: 0.7rem;
  color: #666666;
  display: block;
}


.prfile-pages .profile-menu li i {
  display: inline-block;
  margin-right: 10px!important;
  border-radius: 0;
}

.prfile-pages .profile-menu .react-tabs__tab--selected {
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  background: #16498a;
  color: #fff;
}

.prfile-pages .profile-menu li:hover {
  background: #16498a;
  color: #fff;
} 
.prfile-pages .profileFormOuter h2,.prfile-pages h4 {
  padding: 0 0 10px 0;
  margin-bottom: 10px;
  font-size: 1.89rem;
  font-weight: bold;
}
.prfile-pages .react-tabs__tab-panel {
  border: 1px solid #dee2e6!important;
  margin-bottom: 40px;padding:24px;
}
.prfile-pages .react-tabs__tab-panel form#profile {
  border: none!important;
  padding: 0!important;
}

.prfile-pages .react-tabs__tab-panel form#profile .profile-form {
  padding: 0;
}
 
.MyFavoriteOrderlist .Restaurants_list,.currentOrderOuter li {
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);
  border-radius: 1.42rem;
  margin-bottom: 2.5rem;
}
.MyFavoriteOrderlist {
  display: flex;
  margin: 0 -10px;
  flex-wrap: wrap;
}

.MyFavoriteOrderlist li {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  padding: 0 10px;
}
.MyFavoriteOrderlist .currentuserimg {
  border-radius: 1.42rem;
  overflow: hidden;
  height: 12.5rem;
}

.MyFavoriteOrderlist .currentuserimg a {
  display: inline-block;
  height: 100%;
  width: 100%;
}
.MyFavoriteOrderlist .currentuserimg img{
  height: 100%;
    width: 100%;
    object-fit: cover;}


    .MyFavoriteOrderlist  h4{ color: #16498a;
    font-family: 'open_sanssemibold'; margin-bottom:0; padding:15px 0 10px 0;
    font-size: 1.28rem;
  }
  .MyFavoriteOrderlist .currentuserimg {
    border-radius: 1.42rem;
    overflow: hidden;
    height: 12.5rem;
}

.MyFavoriteOrderlist .currentuserimg a {
    display: inline-block;
    height: 100%;
    width: 100%;
}
 

.MyFavoriteOrderlist .Restaurants_list .location-reating {
    float: left;
    padding: 0 10px;
    min-width: 50%;
}
  
.MyFavoriteOrderlist .Restaurants_list .wishlist {
  position: relative;
}


.MyFavoriteOrderlist .Restaurants_list .wishlist input {
  position: absolute;
  width: 20px;
  opacity: 0;
  height: 20px;
}
.currentOrderOuter li{padding:15px;}

.currentOrderOuter li .currentuserContent h4 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.currentOrderOuter li h3 {
  font-size: 1rem;
}

.currentOrderOuter li .currentuserContent {
  display: inline-block;
  width: 100%;
  padding: 10px 0 0 0;
}

.currentOrderOuter li .itemOuterBox h5 {
  font-weight: bold;
  font-size: 1.2rem;
  margin-right: 7px;
}

.currentOrderOuter li .itemOuterBox p {
  font-size: 1.1rem;
  color: #696969;
}

.currentOrderOuter li .text-success {
  position: absolute;
  top: 30px;
  right: 40px;
  background: #ffffffdb;
  padding: 3px 15px;
  font-size: 14px;
  box-shadow: 0 0 10px rgba(0,0,0,.5);
  font-weight: 600;
  border-radius: 100px;
}

.currentOrderOuter li .itemOuterBox {
  display: flex;
  align-items: baseline;
}

.currentOrderOuter .cancelOrder {font-size: 14px;}

.currentOrderOuter li {
  margin-bottom: 20px;
}

.notificationOuter .clearAllOuter a {display: inline-block;padding: 6px 20px;background: #efefef;font-size: 14px;font-weight: bold;border-radius: 5px;margin-bottom: 10px;}

.notificationOuter li {
    margin-bottom: 15px;
    border-bottom: 1px solid #f7f7f7;
    padding-bottom: 15px;
    font-size: 14px;
    color: #525252;
}

.notificationOuter li p {
    color: green;
    margin-bottom: 10px;
}

.notificationOuter li h5 {
    color: #333;
    font-weight: 600;
    padding: 5px 0;
}

.notificationOuter .clearAllOuter a i {
    margin-right: 5px;
}

.notificationOuter li:last-child {
    border: none;
}

.notificationOuter .clearAllOuter a {display: inline-block;padding: 6px 20px;background: #efefef;font-size: 14px;font-weight: bold;border-radius: 5px;margin-bottom: 10px;}

.notificationOuter li {
    margin-bottom: 15px;
    border-bottom: 1px solid #f7f7f7;
    padding-bottom: 15px;
    font-size: 14px;
    color: #525252;
}

.notificationOuter li p {
    color: green;
    margin-bottom: 10px;
}

.notificationOuter li h5 {
    color: #333;
    font-weight: 600;
    padding: 5px 0;
}

.notificationOuter .clearAllOuter a i {
    margin-right: 5px;
}

.notificationOuter li:last-child {
    border: none;
}

form#changePassId .input-outer {
    position: relative;
}

form#changePassId .input-outer span {
    position: absolute;
    right: 20px;
    top: 14px;
    font-size: 16px;
    color: #636262;
}

form#changePassId .input-outer input {
    padding: 20px 15px;
    line-height: 1.5;
    height: 48px;
    font-size: 16px;
}
.addressmodalbox li {
  padding: 15px;
  border-bottom: 1px solid #eee;
  margin-bottom: 13px;
  background: #fff;
  color: #333;
  position: relative;
}

.addressmodalbox li a {
  color: #333;
}

.addressmodalbox li .edittext a {
  background: #eee;
  margin-right: 10px;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
}

.addressmodalbox li:last-child {
  border: none;
}

.addressmodalbox .newAddressBTN button {
  background: #16498a;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  color: #fff;
}

.addressmodalbox .fa.fa-star {
  color: #16498a;
}

.nav-tabs.status-tabs {
  display: block;
} 
 
.nav-tabs.status-tabs  .react-tabs__tab-list {
  border: none;
  margin-bottom: 10px;
}

.nav-tabs.status-tabs ul.react-tabs__tab-list {
  border-bottom: 1px solid #ddd!important;
}

.react-tabs__tab--selected {
  border: none!important;
  bottom: 0px!important;
  color: #f00!important;
}

.nav-tabs.status-tabs .react-tabs__tab-list li {
  font-size: 14px;
  padding: 10px;
}

.nav.nav-tabs.status-tabs {
  margin: 0 0 36px 0;
}


 
.searchform {background: #16498a4a;padding: 5px;max-width: 380px;margin: auto;position: relative;}

.searchform input {
    padding: 10px;
    border: none;
    font-size: 14px;
    width: calc(100% - 82px);
}

.searchform .btn {color: #fff!important;
    font-size: 1rem!important;
    padding: 8px 15px;
    border-radius: 0;
}
 .searchform .btn:before {
  display: none;
}


.customeserchlist {
  display: flex;
  flex-wrap: wrap;
}

.customeserchlist .inlineblocklist {
  flex: 0 0 50%;
  max-width: 50%;
} 

/*  Pushpendra 23 Oct */

.feature-restaurants .resItem a {

  min-width: 100%;

}