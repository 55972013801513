@media (min-width: 1600px){
   
}

@media (max-width: 1919.98px){
.full_container{ max-width: 100%;}

}

@media (max-width: 1599.98px){
 html{ font-size: 13px;}
.how-work-features{ background-size:cover;}
}

@media screen and (max-width: 1439.98px) {

.latest-sec .owl-next{ right:-5rem;}
.latest-sec .owl-prev{ left:-5rem;}
    
 }


@media (max-width: 1365.98px){

}


@media (max-width: 1199.98px){
html { font-size: 12px;}
.banner_title{ font-size:3rem; line-height:normal;}
.categories-sec .owl-carousel.owl-loaded{ padding-right:0;}
.categories-sec .slider-sec{ position:inherit; left:inherit; top:inherit; transform:initial; width:100%; padding-top:0rem;}
.categories-sec .owl-next{ right:-1rem;}
.categories-sec .owl-prev{ left:-1rem;}
.logo img{ width:13rem;}
.Service-text{ left:7rem;}
.categories-sec{ padding:3rem 0rem;}
.home_banner_block .item{ height:24rem;}
.categories-sec{ height:auto;}
.latest-sec{ padding:3rem 0rem}
.features-sec{ padding:3rem 0rem 2rem;}
.how-work-features{ padding:3rem 0rem 3rem;}
footer .space{ padding:3rem 0rem}
.sec-title{ font-size:2rem;}   
.latest-sec .owl-prev{ left:-2rem;}
.latest-sec .owl-next{ right:-2rem;}
.box-white{ height:18rem;}

.login-box{ max-width:400px;}
.login-page:after{ width:50%;}
.login-page:before{ width:50%;}
.logo-login{ width:rem; height:8rem;}
.login-box .text-blue{ font-size:2rem;}
.product-info{ padding-right:25px;}
}


@media (max-width: 991.98px){

.navigation{ background-color:#fff;}
.navbar-expand-md > .container{ -webkit-box-align: center; -ms-flex-align: center; align-items: center;}
/*.navbar-expand-md .navbar-nav {margin-top:1rem !important;}*/
header .collapse:not(.show){ display: none !important;}
.navbar-expand-md .navbar-toggler{ display: block !important;}
.navbar-expand-md .navbar-collapse{ width: 100%; -ms-flex-wrap: wrap; flex-wrap: wrap; position: static; -webkit-transform: translate(0%, 0%); transform: translate(0%, 0%); margin:6px -15px 0px; border-top:2px solid #f2f2f2;}
.navbar-expand-md .navbar-nav{ width: 100%; background-color: #ffffff; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }
.navigation .navbar-nav li{ padding:1rem 1rem;}
.navigation .navbar-nav li a{ font-size:1.14rem;}
.logo img{ width:9rem;}
.Service-text{ left:6rem; display:none;}
.banner_title{ font-size:2.40rem;}
.owl-prev span, .owl-next span{ font-size:4rem;}
.box-white .title{ font-size:1.3rem;}
.btn-order{ width:12rem}
.box-features{ margin-bottom:2rem;}
footer:after,
footer:before{ display:none;}
.navigation .navbar-nav li{ border-bottom:1px solid #f2f2f2;}
.navigation .navbar-nav li:last-child{ border-bottom:none; padding-bottom:0;}
/*.login-box{ max-width:320px;}*/
.login-box{ margin:0 auto; }
.login-page:before{ background-image:none; width:100%; background-color:rgba(0,0,0,0.3); z-index:9; border:0;}
.login-page:after{  width:100%; background-position: center center; border:0;}
.login-box{ background-color:#fff; z-index:99; position:relative; padding:2rem 2rem 1rem; border-radius:0.25rem;}
.how-work-features .row:before{ display:none;}
.brand-info .title{ font-size:2.35rem;}
.brand-info .rating i{ font-size:1.4rem;}
.product-info .nav-tabs .nav-link{ font-size:1.14rem; padding:.9rem 1rem;}
.brand-img{width:8rem;}
.brand-info{ width:calc(100% - 8rem);}    
.product-info .col-lg-6{ border:0px!important;}
.location-popup .modal-dialog{ max-width:720px;}    
}

@media (max-width: 767.98px){
.banner_data{ max-width:100%; width:70%; padding:0 15px;}
.home_banner_block .item{ height:auto; padding:10rem 0rem;}
.home_banner_block .owl-carousel .owl-dots{ bottom:1rem;}
.owl-carousel .owl-dots{ position:absolute; bottom:0rem; left:0; width:100%; display: flex; align-items: center; justify-content: center;}
.owl-carousel button.owl-dot{ width:1rem; height:1rem; border-radius:100%; background-color:red; margin:0 3px; border:1px solid transparent;}
.owl-carousel button.owl-dot.active{ background-color:#fff; border-color:red;}

.categories-sec{ padding:1.5rem 0rem;}
.box-white .title{ font-size:1rem;}
.features-sec{ padding:1.5rem 0rem 0.5rem;}
.box-white{ height:15rem;}
.latest-sec{ padding:1.5rem 0rem;}
.rounded-box{ width:10rem; height:10rem;}
.order-box .title{ font-size:1.20rem;}
.sec-title{ font-size:1.3rem;}
.box-features{ margin-bottom:1rem;}
.box-features{ border-radius:0.25rem;}
.features-img{ border-radius:0.25rem;}
.copyright{  padding:1rem 0rem;}
.how-work-features{ padding:1.5rem 0rem 0.5rem;}
.how-work-content{ display:flex; align-items:center; padding-bottom:1rem;}
.how-work-info{ text-align:left; padding-left:1rem; width: calc(100% - 18%);}
.how-work-img{ width:18%;}
.footer-col{ max-width:100%;}

.footer-logo{ display:none;}
footer .space{ padding:1rem 0rem;}
.social-link a{ font-size:1.4rem;}
.how-work-content .title{ font-size:1.20rem;}
.logo-login{ width:6rem; height:6rem;}
.login-box .text-blue{ font-size:2rem;}
.sidebar{ padding-bottom: 0; display:none; margin-bottom:2rem; padding-bottom:2rem;}
.sidebar.show{  display:block;}
.btn-order.h-100{ height:2.85rem!important;}
.product-sidebar{ width:100%; padding:0;}
.product-main{width:100%;}
.brand-info .title{ font-size:2rem;}
.location-popup .modal-body{ padding:1rem;}
.location-popup .modal-dialog{ max-width:100%; margin:0.5rem;}
.location-popup .modal-body a{ padding-bottom:0.5rem;}
.location-popup .modal-body h3{ font-size:1.16rem;}
.location-popup .centerBtn .btn{ width:70px;}
.location-popup .form-control{ padding:.375rem 6rem .375rem .75rem;}
}



@media (max-width: 575.98px){
.navigation .navbar{ padding: 0.5rem 15px;}
.navigation.fixed-top nav{ padding: 0.5rem 15px;}
.banner_data{ width:100%; }
.location .location-tag{ font-size:0;} 
.banner-search .btn-search{ width:6rem; font-size:1rem;}
.banner-search .form-control{ width:calc(100% - 7rem); padding-left:3rem;}
.btn-add{ min-width:70px;}    
}



@media (max-width: 479.98px){
.banner_title{ font-size:2rem;}
.main .col-6{  max-width:100%; flex:100%;}
.features-info .open-tag{ width:auto;}
.features-info .order-time div{ width:auto;}
.features-info .order-time div{ border:0}
.brand-info{ width:100%; padding-left:0px!important;}
.product-info .nav-tabs .nav-item{ width:100%;}
.nav-tabs .nav-link{ border:1px solid #dee2e6!important; margin:0;}
.checkout-main .custom-select{width:100%!important;}
.checkout-main .form-control{width:100%; margin:5px 0px;}
}


@media (max-width:360.98px){
.banner_title{ font-size:1.50rem;}
.social-link a {width: 2.5rem;height: 2.5rem;}
}
